import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import useConversionFramework from '~hooks/useConversionFramework';

const DefaultPlaylist = loadable(() => import('./Playlist'));
const MarketingHeroPlaylist = loadable(() => import('./MarketingHeroPlaylist'));

const MARKETING_PAGE_LAYOUTS = {
  DEFAULT: 'Default',
  HERO_COVER: 'Hero Cover'
};

const Playlist = props => {
  const { isMarketingSite } = useConversionFramework();
  const { playlist } = props;

  const showHeroPlaylist = useMemo(
    () => playlist?.pageLayout === MARKETING_PAGE_LAYOUTS.HERO_COVER,
    [playlist?.pageLayout]
  );

  if (isMarketingSite && showHeroPlaylist) {
    return <MarketingHeroPlaylist {...props} />;
  }
  return <DefaultPlaylist {...props} />;
};

Playlist.propTypes = {
  playlist: PropTypes.shape({
    title: PropTypes.string,
    uid: PropTypes.string,
    author: PropTypes.shape({}),
    materials: PropTypes.arrayOf(PropTypes.shape({})),
    prismicId: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        uid: PropTypes.string
      })
    ),
    required_subscription_plan: PropTypes.number,
    allowFullWatch: PropTypes.bool,
    nextPlaylist: PropTypes.shape({
      localFile: PropTypes.shape({}),
      url: PropTypes.string
    }),
    suggestedPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}),
        url: PropTypes.string
      })
    ),
    trendingPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}),
        url: PropTypes.string
      })
    ),
    popularPlaylists: PropTypes.arrayOf(
      PropTypes.shape({
        localFile: PropTypes.shape({}),
        url: PropTypes.string
      })
    ),
    firstVideo: PropTypes.shape({
      cover_image: PropTypes.shape({
        localFile: PropTypes.shape({}),
        alt: PropTypes.string,
        url: PropTypes.string
      }).isRequired
    }).isRequired,
    heroCoverImage: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string
    }),
    pageLayout: PropTypes.oneOf(Object.values(MARKETING_PAGE_LAYOUTS))
  }).isRequired,

  session: PropTypes.shape({
    isSubscriber: PropTypes.bool,
    isPrivilegedMember: PropTypes.bool,
    isProducer: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    user: PropTypes.shape({
      subscription_plan_id: PropTypes.number
    })
  }),
  videoId: PropTypes.number,
  seekToSecond: PropTypes.number,
  hasPodcastSubscriptionLink: PropTypes.bool,
  onProgress: PropTypes.func,
  promotionalVideos: PropTypes.arrayOf(
    PropTypes.shape({
      promoVideoUrl: PropTypes.string.isRequired,
      accessLevel: PropTypes.string.isRequired,
      isFirst: PropTypes.bool.isRequired,
      isLast: PropTypes.bool.isRequired
    })
  ),
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  giftToken: PropTypes.string,
  senderFullName: PropTypes.string,
  isMarketingSite: PropTypes.bool,
  allowedSeconds: PropTypes.number
};

Playlist.defaultProps = {
  session: { user: {} },
  videoId: undefined,
  seekToSecond: undefined,
  hasPodcastSubscriptionLink: false,
  giftToken: undefined,
  senderFullName: undefined,
  onProgress: () => {},
  promotionalVideos: [],
  isMarketingSite: false,
  allowedSeconds: 90 // default allowed seconds for the marketing site
};

export default Playlist;
